import { useContext } from "react";
import DataContext from "../../../../../../../context/DataContext";
import { useQuery } from "@tanstack/react-query";
import { user } from "../../../../../../api/endpoints/user";
import { STRINGS } from "./strings";
import ScrollList from "../ScrollList";
import { LIST_TYPES } from "../listTypes";
import {
  DashboardFilterDispatchContext,
  DashboardFilterStateContext,
} from "../../context/DashboardFilterContext";
import { toggleFetchMedicalStatus } from "../../state/actions";
import { GenerateQueryParameterString } from "../../../../../../global/GenerateQueryParamString";
import SearchContext from "../../../../../../../context/SearchContext";

const MedicalStatusDashFilter = () => {
  const { accessToken } = useContext(DataContext);
  const dashboardCardState = useContext(DashboardFilterStateContext);
  const dashboardCardDispatch = useContext(DashboardFilterDispatchContext);
  const { selectedCaseStatuses, selectedStatuses } = useContext(SearchContext);

  const { isPending, error, data, isFetching } = useQuery({
    queryKey: ["medical-stats-filters"],
    queryFn: async () => {
      const response = user
        .getMedicalStatusStats(
          accessToken,
          GenerateQueryParameterString(
            {
              ...(selectedCaseStatuses.length === 1
                ? { overall_status__in: selectedCaseStatuses }
                : { overall_status: selectedCaseStatuses }),
              ...(selectedStatuses.length === 1
                ? { status__in: selectedStatuses }
                : { status: selectedStatuses }),
            },
            true
          )
        )
        .then((res) => {
          
          return res;
        })
        .catch((err) => err);
        toggleFetchMedicalStatus(dashboardCardDispatch, false);
      return await response;
    },
    enabled: dashboardCardState.getMedicalStatus,
  });

  if (isPending) return STRINGS.LOADING + "...";

  if (error) return STRINGS.ERROR + ": " + error.message;

  return (
    <div
      id="Medical-Status-Dashboard-Filter"
      data_testid="Medical-Status-Dashboard-Filter"
      className="overflow-y-auto"
    >
      {isFetching ? (
        STRINGS.UPDATING + "..."
      ) : (
        <ScrollList
          array={data.medical_status_counts}
          type={LIST_TYPES.BUTTON_LIST}
          color="purple"
        />
      )}
    </div>
  );
};

export default MedicalStatusDashFilter;
